// extracted by mini-css-extract-plugin
export var container = "measures-module--container--dr9LI";
export var content = "measures-module--content--mZYUa";
export var backgroundContainer = "measures-module--backgroundContainer--Hb2fF";
export var background = "measures-module--background--QcmM3";
export var informationContainer = "measures-module--informationContainer--3PI6S";
export var titleContainer = "measures-module--titleContainer--GKdpB";
export var title = "measures-module--title--31rdR";
export var descriptionContainer = "measures-module--descriptionContainer--okMKZ";
export var description = "measures-module--description--LQXkM";
export var iconsContainer = "measures-module--iconsContainer--sFoGx";
export var iconSquare = "measures-module--iconSquare--MEGTp";
export var icon = "measures-module--icon--WPFkB";