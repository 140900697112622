import React from "react";
import * as styles from "./links.module.scss";
import {graphql} from "gatsby";
import { HomeNavigationLinksFieldsFragment } from "../../../../graphql-types";
import Link from "./link/link";

type ReactProps = {
    data: HomeNavigationLinksFieldsFragment
}

const Links: React.FC<ReactProps> = ({ data }) => {
    const links = data.navigationLinks;

    return (
        <section className={styles.container}>
            <ul className={styles.linksList}>
                {links.map((link, index) => {

                    return (
                        <Link key={link.id} data={link} />
                    )

                })}
            </ul>
        </section>
    )
}

export const fragment = graphql`
    fragment HomeNavigationLinksFields on DatoCmsHomePage {
        navigationLinks {
            id
            title
            titleAttribute
            slug
            mainColor {
                hex
            } 
            v2MainColor {
                hex
            } 
            v2Icon {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }      
            icon {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
            iconHover {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
            backgroundIcon {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
            backgroundIconHover {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
        }
  }
`

export default Links;