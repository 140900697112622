import React from "react"
import * as styles from "./measures.module.scss"

type ReactProps = {
    data: any
}

const Measures: React.FC<ReactProps> = ({data}) => {

    return (
        <section className={styles.container}>
            <div className={styles.content}>
                <div className={styles.backgroundContainer}>
                    <img className={styles.background} src={data.backgroundImage.url} alt="People with mask on a bus"/>
                </div>
                <div className={styles.informationContainer}>
                    <div className={styles.titleContainer}>
                        <p className={styles.title}>
                            {data.measuresTitle}
                        </p>
                    </div>
                    <div className={styles.descriptionContainer}>
                        <p className={styles.description}>
                        {
                           data && data.measuresSubtitle && data.measuresSubtitle.length < 70 ? 
                            data.measuresSubtitle : `${data.measuresSubtitle.substring(0, 70)}...`
                        }
                        </p>
                        <div className={styles.iconsContainer}>
                            {
                             data && data.maskIcon && (
                            <div className={styles.iconSquare}>
                                <img className={styles.icon} src={data.maskIcon.url} alt="Mask icon"/>
                            </div>
                             )}
                               {
                             data && data.handIcon && (
                            <div className={styles.iconSquare}>
                                <img className={styles.icon} src={data.handIcon.url} alt="Hand icon"/>
                            </div>
                             )}
                               {
                             data && data.distanceIcon && (
                            <div className={styles.iconSquare}>
                                <img className={styles.icon} src={data.distanceIcon.url} alt="Distance icon"/>
                            </div>
                             )}
                              {
                             data && data.sprayIcon && (
                            <div className={styles.iconSquare}>
                                <img className={styles.icon} src={data.sprayIcon.url} alt="Product icon"/>
                            </div>
                             )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

}

export default Measures;