import React, { useEffect, useRef, useState } from "react"
import * as styles from "./reviews.module.scss";
import {graphql} from "gatsby";
import {HomeReviewsFieldsFragment} from "../../../../graphql-types";
import useInterval from "@use-it/interval"
import Review from "./review/review";
import useWindowWidth from "../../../hooks/use-window-width";
import { ReactSVG } from "react-svg"
import CarouselRightArrow from "../../../../static/vectors/carousel_right_arrow.svg"
import Download from "../../../../static/vectors/download.svg"
import NewsTextImage from "./news-image-text/news-image-text";
import NewsTextColor from "./news-text-color/news-text-color";
import NewsImageOnBackground from "./news-image-background/news-image-background";
import SimpleNewsText from "./simple-news-text/simple-news-text";

type ReactProps = {
    data: HomeReviewsFieldsFragment
}

const Reviews: React.FC<ReactProps> = ({data}) => {

    const carouselContentRow = useRef(null)
    const [isScrolling, setIsScrolling] = useState(true)
    const [isHovered, setIsHovered] = useState(false)
    const isMobile = useWindowWidth() <= 1200;

    const news = data.news;

      /* The carousel has to scroll to the middle of the div once in order to make it infinite/circular afterwards */
  useEffect(() => {
    const itemWidth = carouselContentRow.current.children[0].clientWidth
    carouselContentRow.current.scrollTo({
      left: itemWidth,
      top: 0,
      behavior: "instant",
    })
    setTimeout(() => {
      /* wait for 6sec to start scrolling */
    }, 60000)
  }, [])

  useInterval(() => {
    if (!isScrolling) {
      return
    }
    scrollToCard(document?.dir === "rtl" ? -1 : 1, true)
  }, 60000)

  const scrollToCard = (scrollDirection: number, shouldScroll?: boolean) => {
    if (!shouldScroll) {
      setIsScrolling(false)
    }

    if (scrollDirection === -1) {
      carouselContentRow.current.prepend(carouselContentRow.current.lastElementChild)
    } else if (scrollDirection === 1) {
      carouselContentRow.current.appendChild(carouselContentRow.current.firstElementChild)
    }

    const rtlRatio = document?.dir === "rtl" ? -1 : 1
    const itemWidth = carouselContentRow.current.children[0].clientWidth
    carouselContentRow.current.scrollBy({
      left: itemWidth * scrollDirection * rtlRatio,
      right: itemWidth * scrollDirection * rtlRatio,
      top: 0,
      behavior: "smooth",
    })
  }
    return (
        <section className={styles.container}>
            <div className={styles.content}>
            <div
            className={styles.carouselContent}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            >
            <button
                className={styles.leftArrow}
                onClick={() => scrollToCard(-1)}
                hidden={!isMobile && !isHovered}
                aria-label="Élément précédent"
            >
                <ReactSVG src={CarouselRightArrow} />
            </button>
            <div className={styles.carouselContentRow} ref={carouselContentRow}>
            { news.map(review => {
              if(review.link?.__typename == "DatoCmsReviewHomeNews1"){
                return <NewsTextImage data={review.link} />
              } else if (review.link?.__typename == "DatoCmsReviewHomeNews2") {
                return <NewsTextColor data={review.link} />
              } else if (review.link?.__typename == "DatoCmsReviewHomeNews3") {
                return <NewsImageOnBackground data={review.link}/>
              } else if (review.link?.__typename == "DatoCmsReviewHomeNews4") {
                return <SimpleNewsText data={review.link}/>
              }

            })}
            </div>
            <button
                className={styles.rightArrow}
                onClick={() => scrollToCard(-1)}
                hidden={!isMobile && !isHovered}
                aria-label="Élément suivant"
            >
                <ReactSVG src={CarouselRightArrow} />
            </button>
            </div>
            </div>
            <div className={styles.titleContent}>
                    <p className={styles.title}>
                      {data.guideDuBienVoyagerTitle}
                    </p>
                    <p className={styles.subtitle}>
                      {data.guideDuBienVoyagerText}
                    </p>
                    <a className={styles.boutton} aria-label={'data.buttonTitle'} href="https://q-ksa-mad-common-euw-apim.azure-api.net/tourism/guide/GUIDE_DU_BIEN_VOYAGER.pdf" target="_blank">
                       <ReactSVG src={Download} />
                    </a>
                </div>
        </section>
    )

}

export const fragment = graphql`
    fragment HomeReviewsFields on DatoCmsHomePage {
        reviews {
            id
            quote
            author
            job
            backgroundImage {
                format
                url
                alt
                gatsbyImageData(layout:FIXED)
            }
            backgroundColor {
                hex
            }
            textColor {
                hex
            }
        }
        guideDuBienVoyagerTitle
        guideDuBienVoyagerText
        news {
          id
          link {
            __typename
            ... on DatoCmsReviewHomeNews1 {
              title
              text
              image {url}
              backgroundColorText{hex}
              textColor{hex}
            }
            ... on DatoCmsReviewHomeNews2 {
              title
              text
            }
            ... on DatoCmsReviewHomeNews3 {
              image {url}
              title
              text
            }
            ... on DatoCmsReviewHomeNews4 {
              title
              texte
              backgroundColor {
                hex
              }
            }
          }
        }
    }
`

export default Reviews;