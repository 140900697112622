//TODO:AME REPLACE TEXT OF SUBTITLE WITH DATO CMS CONTRIBUTION
import React from "react";
import * as styles from "./cover.module.scss";
import coverImage from '../../../assets/images/cover.png';
import keolis from '../../../assets/images/logokeolis.png';
import { graphql } from "gatsby";
import { HomeCoverFieldsFragment } from "../../../../graphql-types";
import {displayImage} from "../../../global/functions/functions";
import useWindowWidth from "../../../hooks/use-window-width";

type ReactProps = {
    data: HomeCoverFieldsFragment
}

const Cover: React.FC<ReactProps> = ({ data }) => {
    const isMobile = useWindowWidth() <= 800;

    return (
        <section className={styles.container}>
            <div className={styles.imageContainer}>
                {displayImage(isMobile ? data.cover[0].mobileImage : data.cover[0].busBackgroundImage, styles.coverImage, "cover")}
                {isMobile && <div className={styles.titleContainer}>
                <div className={styles.titleContent}>
                <img src={keolis} className={styles.logoContent} alt=""/>
                    <h1 className={styles.title}>
                        {data.title}
                    </h1>
                    <p className={styles.subtitle}>
                        {data.subtitle}
                    </p>
                    <a aria-label={data.buttonTitle} href={`/devis-location-car`} className={styles.coverButton}>
                        {data.buttonTitle}
                    </a>
                </div>
            </div>}
            </div>
            {
                !isMobile && 
                <div className={styles.titleContainer}>
                <div className={styles.titleContent}>
                <img src={keolis} className={styles.logoContent} alt=""/>
                    <h1 className={styles.title}>
                        {data.title}
                    </h1>
                    <p className={styles.subtitle}>
                        {data.subtitle}
                        {/* Pour aller tout près ou très loin, pour une journée ou une semaine, pour 30 personnes comme pour 500. */}
                    </p>
                    <a aria-label={data.buttonTitle} href={`/devis-location-car`} className={styles.coverButton}>
                        {data.buttonTitle}
                    </a>
                </div>
            </div>
            }
        </section>
    )

}

export const fragment = graphql`
    fragment HomeCoverFields on DatoCmsHomePage {
        title
        subtitle
        description
        buttonTitle
        cover {
            title
            backgroundImage {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
            mobileImage {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
            busBackgroundImage {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
            primaryBackgroundImage {
                alt
                title
                format
                url
                gatsbyImageData(layout: FIXED)
            }
        }
    }
`

export default Cover;