// extracted by mini-css-extract-plugin
export var container = "cover-module--container--Et6wA";
export var imageContainer = "cover-module--imageContainer--2+AGC";
export var coverImage = "cover-module--coverImage--AMQIv";
export var titleContainer = "cover-module--titleContainer--RL2NA";
export var backgroundImage = "cover-module--backgroundImage--xxHuf";
export var titleContent = "cover-module--titleContent--rN4Xg";
export var logoContent = "cover-module--logoContent--woyJl";
export var title = "cover-module--title--luQMP";
export var subtitle = "cover-module--subtitle--ja7PP";
export var coverTextContainer = "cover-module--coverTextContainer--kRHCH";
export var coverTextContent = "cover-module--coverTextContent--6TF3E";
export var coverDescription = "cover-module--coverDescription--xzO4E";
export var coverButton = "cover-module--coverButton--sue-4";