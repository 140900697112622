import React from "react";
import useWindowWidth from "../../../../hooks/use-window-width";

import * as styles from "./newstextcolor.module.scss"

type RenderProps = {
    data: any,
}

const NewsTextColor:React.FC<RenderProps> = ({data}) => {
    const isMobile = useWindowWidth() <= 800;
    const maxLength = isMobile ? 140 : 450;

    return (
      <div className={styles.container}>
          <div className={styles.title}>
              {data.title}
          </div>
          <div className={styles.text}>
          {
                data && data.text && data.text.length < maxLength ? 
                data.text : `${isMobile ? data.text.substring(0, maxLength) : data.text.substring(0, maxLength)}...`
              }
          </div>
      </div>
    )

}

export default NewsTextColor;