// extracted by mini-css-extract-plugin
export var container = "reviews-module--container--Rqwhq";
export var titleContent = "reviews-module--titleContent--jw7c3";
export var boutton = "reviews-module--boutton--mZuq7";
export var title = "reviews-module--title--veZDc";
export var subtitle = "reviews-module--subtitle--KYibp";
export var content = "reviews-module--content--pJPL0";
export var carouselContent = "reviews-module--carouselContent--9IkaX";
export var carouselContentRow = "reviews-module--carouselContentRow--VhTI-";
export var image = "reviews-module--image--vnQYM";
export var video = "reviews-module--video--LXECE";
export var leftArrow = "reviews-module--leftArrow--Kqfit";
export var rightArrow = "reviews-module--rightArrow--evDFX";