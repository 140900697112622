import React from "react";
import useWindowWidth from "../../../../hooks/use-window-width";
import * as styles from "./newsimages.module.scss"

type RenderProps = {
    data: any,
}

const NewsTextImage:React.FC<RenderProps> = ({data}) => {
  const isMobile = useWindowWidth() <= 800;
  const maxLength = 110;
    if (isMobile) {
        return <div  className={styles.mobileBackgroundContainer}>
        <div className={styles.backgroundContainer}>
          <img className={styles.backgroundMobile} src={data.image.url} alt="image background news"/>
        </div>
          <div className={styles.titleContainer}>
              <p className={styles.titleMobile}>
                  {data.title}
              </p>
          <div className={styles.descriptionContainer}>
            <p className={styles.description}>
              {
                data && data.text && data.text.length < maxLength ? 
                data.text : `${isMobile ? data.text.substring(0, maxLength) : data.text.substring(0, maxLength)}...`
              }
              </p>
          </div>
        </div>
        </div>
    } else {
        return (
          <div className={styles.container}>
              <div className={styles.leftContent}>
                  <div className={styles.title}>
                      {data.title}
                  </div>
                  <div className={styles.text}>
                    {
                      data && data.text && data.text.length < maxLength ? 
                      data.text : `${data.text.substring(0, maxLength)}...`
                    }
                  </div>
              </div>
              <div className={styles.rightContent}>
                  <img className={styles.background} src={data.image.url} alt="image de news"/>
              </div>
          </div>  
        )
     }
}

export default NewsTextImage;