import React from "react";
import useWindowWidth from "../../../../hooks/use-window-width";
import * as styles from "./newsimagebackground.module.scss"



type RenderProps = {
    data: any,
}

const NewsImageOnBackground:React.FC<RenderProps> = ({data}) => {
  const isMobile = useWindowWidth() <= 800;
  const maxLength = isMobile ? 200 : 450;
    return (
      <div className={styles.wrapper}>
        <div className={styles.backgroundContainer}>
          <img className={styles.background} src={data.image.url} alt="image background news"/>
        </div>
          <div className={styles.titleContainer}>
              <p className={styles.title}>
                  {data.title}
              </p>
          <div className={styles.descriptionContainer}>
          <p className={styles.description}>
            {
              data && data.text && data.text.length < maxLength ? 
              data.text : `${isMobile ? data.text.substring(0, maxLength) : data.text.substring(0, maxLength)}...`
            }
              </p>
          </div>
        </div>
      </div>
    )
}

export default NewsImageOnBackground;