// extracted by mini-css-extract-plugin
export var container = "newsimages-module--container--j+jCM";
export var mobileBackgroundContainer = "newsimages-module--mobileBackgroundContainer--w4Fbp";
export var leftContent = "newsimages-module--leftContent--3rU0I";
export var title = "newsimages-module--title--RI52u";
export var text = "newsimages-module--text--ysskO";
export var rightContent = "newsimages-module--rightContent--zKbC9";
export var background = "newsimages-module--background--fA8te";
export var backgroundContainer = "newsimages-module--backgroundContainer--5M97W";
export var backgroundMobile = "newsimages-module--backgroundMobile--BPo1F";
export var titleContainer = "newsimages-module--titleContainer--+gA3E";
export var titleMobile = "newsimages-module--titleMobile--xPGe8";
export var descriptionContainer = "newsimages-module--descriptionContainer--0ea8d";
export var description = "newsimages-module--description--thkzb";