import React from "react";
import useWindowWidth from "../../../../hooks/use-window-width";
import * as styles from "./simplenewstext.module.scss"


type RenderProps = {
    data: any,
}
const SimpleNewsText:React.FC<RenderProps> = ({data}) => {
  const isMobile = useWindowWidth() <= 800;
  const maxLength = isMobile ? 140 : 250;

    return (
      <div className={styles.container}>
        <div className={styles.title}>
          {data.title}
        </div>
        <div className={styles.text}>
        {
              data && data.texte && data.texte.length < maxLength ? 
              data.texte : `${isMobile ? data.texte.substring(0, maxLength) : data.texte.substring(0, maxLength)}...`
            }
        </div>
      </div>
    )

}

export default SimpleNewsText;