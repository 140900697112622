import React from "react";
import * as styles from "./link.module.scss";
import {useHover} from "../../../../hooks/useHover";
import {displayImage} from "../../../../global/functions/functions";
import useWindowWidth from "../../../../hooks/use-window-width";

type ReactProps = {
    data: any
}

const Link: React.FC<ReactProps> = ({data}) => {

    const [hoverRef, isHovered] = useHover<HTMLDivElement>();
    const isMobile = useWindowWidth() <= 800;

    return (
        <li
            ref={hoverRef}
            className={styles.linkBox}
            style={{ backgroundColor: !isMobile && isHovered ? data.v2MainColor.hex : "rgba(244, 244, 244, 255)" }}
        >
            <a title={data.titleAttribute || " "} aria-label={`Link to ${data.title}`} className={styles.link} href={data.slug}>
                <div className={styles.linkBackgroundLogo}>
                    {displayImage(!isMobile && isHovered ? data.backgroundIconHover : data.backgroundIcon, styles.bgLogo)}
                </div>
                <div className={styles.linkIcon}>
                    {displayImage(!isMobile && isHovered ? data.iconHover : data.v2Icon, styles.icon)}
                </div>
                <p
                    className={styles.linkTitle}
                    style={{color: data.v2MainColor.hex}}
                >{data.title}</p>
            </a>
        </li>
    )

}

export default Link;