import React from "react";
import Layout from "../components/layout/layout";
import Cover from "../components/home/cover/cover";
import Links from "../components/home/links/links";
import { graphql } from "gatsby";
import { HomeQuery } from "../../graphql-types";
import Reviews from "../components/home/reviews/reviews";
import Measures from "../components/home/measures/measures";

type RenderProps = {
    data: HomeQuery
}

const Index:React.FC<RenderProps> = ({ data }) => {

    return (
        <Layout data={data.datoCmsFooter} stickyButtonsData={data.datoCmsWebStickyButton} chatbotData={data.datoCmsChatbot} seo={data.datoCmsHomePage.seo} pageTemplate="homePage">
            <Cover data={data.datoCmsHomePage} />
            <Links data={data.datoCmsHomePage} />
            <Reviews data={data.datoCmsHomePage} />
            <Measures data={data.datoCmsHomePage} />
        </Layout>
    )
}

export const pageQuery = graphql`
    query Home($locale: String!) {
        datoCmsHomePage(locale: { eq: $locale }) {
            seo {
                title
                description
            }
            ...HomeCoverFields
            ...HomeNavigationLinksFields
            ...HomeReviewsFields
            measuresTitle
            measuresSubtitle
            backgroundImage {
                format
                url
                alt
                gatsbyImageData(layout:FIXED)
            }
            maskIcon {
                format
                url
                alt
                gatsbyImageData(layout:FIXED)
            }
            handIcon {
                format
                url
                alt
                gatsbyImageData(layout:FIXED)
            }
            distanceIcon {
                format
                url
                alt
                gatsbyImageData(layout:FIXED)
            }
            sprayIcon {
                format
                url
                alt
                gatsbyImageData(layout:FIXED)
            }
        }
        
        datoCmsFooter(locale: {  eq: $locale }) {
            ...FooterFields
        }   
        datoCmsWebStickyButton(locale: {  eq: $locale }) {
            ...StickyButtonsFields
        }
        datoCmsChatbot(locale: { eq: $locale }) {
            ...ChatbotFields
        }
    }
`

export default Index
